import revive_payload_client_ZwKzd2byIq from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lgu8cpIiS7 from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rWZkZN5Xht from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ezTwRM8WHF from "/srv/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.3_kpfcgcaizjj7yxsntlabt2rkfa/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_OPGHW45Ps9 from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sB2zsGEf3k from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7hI29lwWvD from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xTo3G3CMFB from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_v7HiB0NfOD from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_YS3wOW9UT5 from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jok5xeiyp7ignpwgmo6ernnzsu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Qb1VmuA9Oh from "/srv/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/srv/app/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_uxLcHO5Yg1 from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34._qljlepwpt5vvdw35gwaot37njm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import titles_jbjKhFoIjb from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5__slq2hktk2pvx3t5hsc2e2yf6ie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_aWkX9YnimC from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5__slq2hktk2pvx3t5hsc2e2yf6ie/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_pH0BlTcG1W from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@_pc6gbscurwktw2tcrh27xfs6di/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_nfh5hPni1i from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@_pc6gbscurwktw2tcrh27xfs6di/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import i18n_mw2mIEykMZ from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_hgD8D4WBoZ from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_wqm2xduxu3dkajxdd3slblcux4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_ksTWBZfObL from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_wqm2xduxu3dkajxdd3slblcux4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_5M0r5goTgh from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.18.7_focus-trap@7.6.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_t_wqm2xduxu3dkajxdd3slblcux4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_nK6tgDJIlt from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_b2MYF5sIpa from "/srv/app/node_modules/.pnpm/@nuxt+icon@1.5.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vu_zwti45t5cqgacvp7esga2i4ljm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_InC36YfEb6 from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.14_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3_webpack@5.95.0_esbuild@0.23.1_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import dayjs_pGiXRjcsJO from "/srv/app/src/plugins/dayjs.ts";
import i18n_VfGcjrvSkj from "/srv/app/src/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/srv/app/src/plugins/sentry.client.ts";
import urql_FlK1jaV38U from "/srv/app/src/plugins/urql.ts";
export default [
  revive_payload_client_ZwKzd2byIq,
  unhead_lgu8cpIiS7,
  router_rWZkZN5Xht,
  _0_siteConfig_ezTwRM8WHF,
  payload_client_OPGHW45Ps9,
  navigation_repaint_client_sB2zsGEf3k,
  check_outdated_build_client_7hI29lwWvD,
  chunk_reload_client_xTo3G3CMFB,
  plugin_vue3_v7HiB0NfOD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YS3wOW9UT5,
  plugin_client_Qb1VmuA9Oh,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_uxLcHO5Yg1,
  titles_jbjKhFoIjb,
  defaultsWaitI18n_aWkX9YnimC,
  siteConfig_pH0BlTcG1W,
  inferSeoMetaPlugin_nfh5hPni1i,
  i18n_mw2mIEykMZ,
  slideovers_hgD8D4WBoZ,
  modals_ksTWBZfObL,
  colors_5M0r5goTgh,
  plugin_client_nK6tgDJIlt,
  plugin_b2MYF5sIpa,
  plugin_InC36YfEb6,
  dayjs_pGiXRjcsJO,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  urql_FlK1jaV38U
]